import React, { useEffect, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { useNavigate, useLocation } from 'react-router-dom'
import * as Yup from 'yup'
import 'yup-phone-lite'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import Button from '@shared/components/button/Button'
import ExternalLink from '@common/components/link/ExternalLink'
import FormCheckbox from '@shared/components/formCheckbox/FormCheckbox'
import PhoneInputField from '@common/components/form/PhoneInputField'
import {
  getRedirectLocationByChallengeTypeAndUserFlow,
  isInvalidPhoneNumber,
  SEGMENT_EVENTS,
  SEGMENT_PAGE_NAMES,
  SEGMENT_SOURCE_DETAILS,
  trackEvent,
  trackPage,
} from '@common/utils'
import { CHALLENGE_TYPES, ROUTE_USER_FLOW } from '@shared/constants'
import { verify } from '@services/serviceUtils'
import { setAuthAction } from '@redux/auth/authActions'
import useFinalizeAccountCreation from '@common/utils/useFinalizeAccountCreation'
import { removeAlertsAction } from '@redux/alerts/alertsActions'
import { ACCOUNT_OPENING_DISCLAIMER_TEXT } from '@common/constants'

import styling from './providePhone.module.scss'

const phoneValidationSchema = Yup.object().shape({
  phone: Yup.string()
    .phone('US', 'A valid phone number is required')
    .required('Required'),
})

const ProvidePhone = () => {
  const [focusedInputSourceDetail, setFocusedInputSourceDetail] = useState(null)
  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const sessionId = useSelector(state => state.auth?.sessionId)
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const { state } = useLocation()

  const { webBrazeSmsOptIn } = useFlags()

  const { userFlow, displayEmail, waitlistCode, ...additionalState } = state || {}

  // Track page visit during signup
  useEffect(() => {
    if (userFlow === ROUTE_USER_FLOW.SIGNUP) {
      trackPage({ name: SEGMENT_PAGE_NAMES.REGISTRATION_PHONE })
    }
  }, [userFlow])

  const { updateAnalyticsAndApplicationStatus } = useFinalizeAccountCreation()

  const handleSubmit = async (values, { setErrors }) => {
    setHasError(false)

    /* If there is still a focused input, make sure to track the event since onBlur is not executed
       when the form submits */
    if (focusedInputSourceDetail) {
      trackEvent({
        event: SEGMENT_EVENTS.registrationFormFieldEntry({
          sourceDetail: focusedInputSourceDetail,
        }),
      })

      setFocusedInputSourceDetail(null)
    }

    const phoneNumber = values?.phone?.replace(/[^\d]/g, '')

    const response = await verify({
      sessionId,
      value: `1${phoneNumber}`,
      onError: error => {
        const message = error?.data?.message
        const invalidPhoneNumber = isInvalidPhoneNumber(message)
        const isDuplicatePhoneNumber = message?.toLowerCase().includes('already in use')

        if (invalidPhoneNumber || isDuplicatePhoneNumber) {
          dispatch(removeAlertsAction())
        }

        if (invalidPhoneNumber) {
          setHasError(true)

          let errorMessage = 'Please enter a valid mobile number.'

          const isVoipNumber = message?.toLowerCase().includes('voip')

          if (isVoipNumber) {
            errorMessage = `VoIP numbers are prohibited. ${errorMessage}`
          }

          setErrorMessage(errorMessage)
        } else if (isDuplicatePhoneNumber) {
          setErrors({ phone: message })
        }
      },
    })

    const data = response?.data

    if (data) {
      const { pathname: redirectLocationPath, state: redirectLocationState } = getRedirectLocationByChallengeTypeAndUserFlow({
        challengeType: data?.challengeType,
        sessionId: data?.sessionId,
        phoneNumber,
        userFlow,
        waitlistCode,
        displayEmail,
        ...additionalState,
      })

      if (data?.challengeType === CHALLENGE_TYPES.VERIFY_PHONE_NUMBER) {
        navigate(redirectLocationPath, { state: redirectLocationState })
      } else {
        await dispatch(setAuthAction(data))

        // Fetch the application data after signing up. Defer navigation
        // to after the data is fetched. Otherwise navigate immediately
        if (userFlow === ROUTE_USER_FLOW.SIGNUP) {
          updateAnalyticsAndApplicationStatus({
            data,
            displayEmail,
            waitlistCode,
            redirectTo: { pathname: redirectLocationPath, state: redirectLocationState },
          })
        } else {
          navigate(redirectLocationPath, { state: redirectLocationState, replace: true })
        }
      }
    }
  }

  const handlePhoneFocus = () => {
    if (userFlow === ROUTE_USER_FLOW.SIGNUP) {
      setFocusedInputSourceDetail(SEGMENT_SOURCE_DETAILS.PHONE)
    }
  }

  const handlePhoneBlur = () => {
    // Track any time the user leaves the phone field during registration
    if (userFlow === ROUTE_USER_FLOW.SIGNUP) {
      trackEvent({
        event: SEGMENT_EVENTS.registrationFormFieldEntry({
          sourceDetail: SEGMENT_SOURCE_DETAILS.PHONE,
        }),
      })

      setFocusedInputSourceDetail(null)
    }
  }

  return (
    <div className='white-card-container'>
      <GreenwoodLogo className='logo' />
      <h1>Mobile phone</h1>
      <div className='create-account-content-wrapper'>
        <p>We’ll use it to keep your account safe and provide support if you need it.</p>
        {hasError && (
          <p className={classNames('error', 'phone-validation-error')}>{errorMessage}</p>
        )}
        <Formik
          initialValues={{
            phone: '',
          }}
          onSubmit={handleSubmit}
          validationSchema={phoneValidationSchema}
          VA
        >
          {({ errors, handleBlur, handleChange, isSubmitting, touched, values }) => (
            <Form data-cy='phone-form'>
              <PhoneInputField
                value={values.phone}
                isInvalid={!!errors.phone && touched.phone}
                errorText={errors.phone}
                isDisabled={isSubmitting}
                fieldName='phone'
                autoFocus
                handlePhoneFocus={handlePhoneFocus}
                handlePhoneBlur={e => {
                  handleBlur(e)
                  handlePhoneBlur()
                }}
                handlePhoneChange={e => {
                  handleChange(e)
                  setHasError(false)
                }}
              />
              <Button type='submit' isLoading={isSubmitting} disabled={!values?.hasAcceptedTerms}>
                NEXT
              </Button>
              <div className='disclaimer-text'>
                <p>Message and data rates may apply.</p>
                {!webBrazeSmsOptIn ? (
                  ACCOUNT_OPENING_DISCLAIMER_TEXT
                ) : (
                  <>
                    <div className={styling['braze-opt-in-box']}>
                      <Field
                        as={FormCheckbox}
                        name='hasAcceptedTerms'
                        id='hasAcceptedTerms'
                        type='checkbox'
                        disabled={isSubmitting}
                      />
                      <span>
                        By checking this box, you consent to receive promotional text messages from
                        Greenwood. You may opt-out at any time by replying STOP. For more
                        information on our messaging policies, please see Greenwood{' '}
                        <ExternalLink
                          to='https://gogreenwood.com/privacy-policy/'
                          className='underlined-link bold'
                        >
                          Privacy Policy
                        </ExternalLink>
                        .
                      </span>
                    </div>
                  </>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default ProvidePhone
