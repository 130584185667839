import React from 'react'

import { ReactComponent as AddMoneyBenefitIcon } from '@common/images/icons/add-money-benefit.svg'
import { ReactComponent as ShieldBenefitIcon } from '@common/images/icons/shield-benefit.svg'
import { ReactComponent as GetPaidEarlyBenefitIcon } from '@common/images/icons/get-paid-early-benefit.svg'
import { ReactComponent as InvestIcon } from '@common/images/icons/signup-invest.svg'

const SidebarTextContent = ({ icon, name, emphasizedName, description }) => {
  return (
    <div className='side-bar-container'>
      {icon}
      <div className='side-bar-text-container'>
        <p>
          {name} <span className='highlight'>{emphasizedName}</span>
        </p>
        <p>{description}</p>
      </div>
    </div>
  )
}

const SignUpSideBar = () => (
  <div className='side-bar'>
    <div className='side-bar-envelope'>
      <div className='side-bar-heading'>
        <p className='title'>Let's Dive Right In</p>
        <p className='description'>
          Sign up takes less than <span className='highlight'>3 minutes.</span>
        </p>
      </div>
      <div className='side-bar-contents'>
        <SidebarTextContent
          icon={<InvestIcon className='icon' />}
          name='Empower You'
          emphasizedName='Wealth Journey'
          description={
            'Dive into smart investing with tools tailored to you.' +
            " Let's transform knowledge into growth one investment at " +
            'a time.'
          }
        />
        <SidebarTextContent
          icon={<AddMoneyBenefitIcon className='icon' />}
          name='Hidden Fees?'
          emphasizedName='Never.'
          description={
            "Very simply, we don't charge you hidden fees to deposit" +
            " or access your money. And we don't have overdraft fees. You made it. " +
            'You keep it. You prosper.'
          }
        />
        <SidebarTextContent
          icon={<ShieldBenefitIcon className='icon' />}
          name='Next-gen security.'
          emphasizedName='No-worries banking.'
          description={
            'Your money is safe. All deposits will be held at ' +
            "FDIC-insured banks. Peace of mind. That's everything. " +
            "That's no-worries virtual banking."
          }
        />
        <SidebarTextContent
          icon={<GetPaidEarlyBenefitIcon className='icon' />}
          name='Get Paid'
          emphasizedName='Early'
          description={
            'Set up Direct Deposit with your Greenwood account ' +
            'to be eligible to receive your pay up to two days early. ' +
            'You work hard for your money. So you deserve to have it earlier.'
          }
        />
      </div>
    </div>
  </div>
)

export default SignUpSideBar
