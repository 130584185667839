import React, { useEffect, useState } from 'react'
import parse from 'react-html-parser'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import CardFlip from '@common/images/animations/card-flip.png'

import Button from '@shared/components/button/Button'

import { BANK_DISCLAIMER } from '@common/constants'
import { staticRoutes } from '@routing/routes'
import { SEGMENT_PAGE_NAMES, trackPage } from '@common/utils'

import styling from './splash.module.scss'

// The card flip animation takes roughly 5s to complete
const ANIMATION_RESTART_DELAY = 5000

const Splash = () => {
  const navigate = useNavigate()

  /* This key is used to force re-render of the card flip container so that the background fade
     animation restarts. It is also used in the card image src attribute to trick the browser into
     thinking a different image asset is to be fetched, otherwise clicking the image will simply
     re-display the finished card animation image, breaking the effect. */
  const [imageKey, setImageKey] = useState(0)
  const [canRestartAnimation, setCanRestartAnimation] = useState(false)

  // When the imageKey changes, temporarily disable subsequent animation restart attempts
  useEffect(() => {
    setCanRestartAnimation(false)

    const restartAnimationTimeoutId = setTimeout(() => {
      setCanRestartAnimation(true)
    }, ANIMATION_RESTART_DELAY)

    // Cleanup, in case the component unmounts before the original timeout's callback completes
    return () => {
      clearTimeout(restartAnimationTimeoutId)
    }
  }, [imageKey])

  // Track splash screen page visits
  useEffect(() => {
    trackPage({ name: SEGMENT_PAGE_NAMES.HOME })
  }, [])

  return (
    <div className={styling.wrapper} data-cy='splash-view'>
      <div className={styling['card-flip']}>
        <div className={styling.bg}>
          <div className={styling.one}></div>
          <div className={styling.two}></div>
          <div className={styling.three}></div>
          <div className={styling.four}></div>
        </div>
        <img
          onClick={() => {
            canRestartAnimation && setImageKey(imageKey + 1)
          }}
          /* Adding an arbitrary search query forces re-cacheing of the image source each time the
             `imageKey` changes */
          src={`${CardFlip}?$${imageKey}`}
          alt='Card Flip Animation '
          data-cy='card-flip-animation'
        />
      </div>
      <div className={styling.content}>
        <GreenwoodLogo className={styling.logo} />

        <h1>
          Modern Banking for&nbsp;
          <br />
          the <span>Culture.</span>
        </h1>

        <p>A mobile banking experience made for Black and Latino customers.</p>

        <Button onDark onClick={() => navigate(staticRoutes.signUp.pathname)}>
          Get Access
        </Button>
        <Button outline onDark onClick={() => navigate(staticRoutes.signIn.pathname)}>
          Sign In
        </Button>

        <p className={styling.disclaimer}>{parse(BANK_DISCLAIMER)}</p>
      </div>
    </div>
  )
}

export default Splash
