import React, { useEffect, useCallback, useState } from 'react'
import { useLocation, useNavigate, Navigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Button from '@shared/components/button/Button'
import PlaidLink from '@common/components/plaidLink/PlaidLink'
import LinkedAccountStatus from './LinkedAccountStatus'

import { useMutation, gql } from '@services/serviceUtils'
import { staticRoutes } from '@routing/routes'
import { addAlertAction } from '@redux/alerts/alertsActions'
import { ALERT_TYPES, DEFAULT_ALERT_DISMISS_DELAY } from '@shared/constants/uiConstants'
import { PLAID_LINKING_CTATYPES } from '@common/constants'

import styling from './linkedAccountDetails.module.scss'

const SERVICES = {
  PLAID: 'PLAID',
  DEBIT_CARD: 'DEBIT_CARD',
}

const removePlaidAccountMutation = gql`
  mutation RemovePlaidAccount($accountId: ID!) {
    removePlaidAccount(accountId: $accountId) {
      success
    }
  }
`

const removeDebitCardMutation = gql`
  mutation RemoveLinkedCard($cardId: ID!) {
    removeLinkedCard(cardId: $cardId) {
      success
    }
  }
`

const ActionButton = ({ action = {} }) => {
  if (action?.service === SERVICES.PLAID) {
    return (
      <PlaidLink
        accountId={action.accountId}
        linkingStatus={action.linkingStatus}
        onAfterSuccess={action.onAfterSuccess}
        isLoading={action.isLoading}
        onClick={action.onClick}
        displayText={action.displayText}
        data-cy={`${action.type.toLowerCase()}-linked-account-button`}
      />
    )
  } else if (action?.service === SERVICES.DEBIT_CARD) {
    return (
      <Button
        isLoading={action.isLoading}
        onClick={action.onClick}
        data-cy={`${action.type.toLowerCase()}-linked-account-button`}
      >
        {action.displayText}
      </Button>
    )
  } else {
    return null
  }
}

const LinkedAccountDetails = ({
  onAfterSuccess = () => {
    /* no-op function */
  },
}) => {
  const [actions, setActions] = useState([])
  const { state } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const linkedAccount = state?.linkedAccount

  const isDebitCardAccount = !!linkedAccount?.address

  const [removePlaidAccount, { loading: removePlaidAccountLoading }] = useMutation(
    removePlaidAccountMutation
  )

  const [removeDebitCard, { loading: removeDebitCardLoading }] = useMutation(
    removeDebitCardMutation
  )

  /* Upon successful account removal, display a success alert then
     navigate back to the `linkedAccounts` page using `replace` so that the user
     cannot navigate back to this page via the browser back button */
  const successTransition = useCallback(
    ({ isRemove }) => {
      const actionText = isRemove ? 'removed' : 'updated'

      dispatch(
        addAlertAction({
          dismissible: false,
          autoDismissDelay: DEFAULT_ALERT_DISMISS_DELAY,
          type: ALERT_TYPES.SUCCESS,
          text: `Success! The linked account was ${actionText}.`,
        })
      )

      onAfterSuccess && onAfterSuccess(isDebitCardAccount)

      navigate(staticRoutes.settingsLinkedAccounts.pathname, { replace: true })
    },
    [dispatch, isDebitCardAccount, navigate, onAfterSuccess]
  )

  const handleRemovePlaidClick = useCallback(async () => {
    const response = await removePlaidAccount({
      variables: { accountId: linkedAccount.id },
    })

    response?.data?.removePlaidAccount?.success && successTransition({ isRemove: true })
  }, [removePlaidAccount, linkedAccount, successTransition])

  const handleRemoveDebitCardClick = useCallback(async () => {
    const response = await removeDebitCard({
      variables: { cardId: linkedAccount.id },
    })

    response?.data?.removeLinkedCard?.success && successTransition({ isRemove: true })
  }, [removeDebitCard, linkedAccount, successTransition])

  const handleBackClick = () => {
    navigate(staticRoutes.settingsLinkedAccounts.pathname)
  }

  /* Upon successful plaid account update, display a success alert then
     navigate back to the `transfers` page using `replace` so that the user
     cannot navigate back to this page via the browser back button */
  const handleAfterSuccess = useCallback(() => {
    successTransition({ isRemove: false })
  }, [successTransition])

  // Assign action for plaid account
  const setPlaidAction = useCallback(
    actionItem => {
      const action = {
        accountId: linkedAccount.id,
        service: SERVICES.PLAID,
        isLoading: removePlaidAccountLoading,
        linkingStatus: linkedAccount.linkingStatus,
        onAfterSuccess: handleAfterSuccess,
        type: actionItem.ctaType,
        displayText: `${actionItem.caption} ${
          actionItem.ctaType.toUpperCase() === 'REMOVE' ? 'ACCOUNT' : ''
        }`,
      }

      if (actionItem.ctaType === PLAID_LINKING_CTATYPES.REMOVE) {
        action.onClick = handleRemovePlaidClick
      }

      return action
    },
    [linkedAccount, removePlaidAccountLoading, handleAfterSuccess, handleRemovePlaidClick]
  )

  // Assign action for debit card
  const setDebitCardAction = useCallback(() => {
    return {
      cardId: linkedAccount.id,
      service: SERVICES.DEBIT_CARD,
      isLoading: removeDebitCardLoading,
      type: 'REMOVE',
      displayText: 'REMOVE CARD',
      onClick: handleRemoveDebitCardClick,
    }
  }, [linkedAccount, removeDebitCardLoading, handleRemoveDebitCardClick])
  // Set any available Plaid action buttons and assign props
  useEffect(() => {
    const _actions = []

    // Plaid Account Actions
    linkedAccount?.ctas?.forEach(cta => {
      _actions.push(setPlaidAction(cta))
    })

    // Debit Card Actions
    if (isDebitCardAccount) {
      _actions.push(setDebitCardAction())
    }

    setActions(_actions)
  }, [linkedAccount, isDebitCardAccount, setPlaidAction, setDebitCardAction])

  return linkedAccount ? (
    <div className={classNames('settings-view-content', styling['linked-account-container'])}>
      <h2>Linked Account/Card</h2>
      <table className={classNames('simple-table', styling['linked-account-table'])}>
        <tbody>
          <tr>
            <th>
              <LinkedAccountStatus linkedAccount={linkedAccount} />
            </th>
          </tr>
        </tbody>
      </table>
      <div className={styling.actions}>
        {actions.map(action => {
          return <ActionButton key={action.type} action={action} />
        })}

        <Button
          disabled={removePlaidAccountLoading || removeDebitCardLoading}
          onClick={handleBackClick}
          ghost
        >
          Back
        </Button>
      </div>
    </div>
  ) : (
    /* This page relies on the `location.state.linkedAccount` to exist to be able to show the
       details here. If these details don't exist, navigate back to the Linked Accounts page */
    <Navigate to={staticRoutes.settingsLinkedAccounts.pathname} />
  )
}

LinkedAccountDetails.propTypes = {
  /**
   * Callback function for removal of Plaid account
   */
  plaidProps: PropTypes.shape({ onAfterSuccess: PropTypes.func }),
  /**
   * Callback function for removal of Debit Card
   */
  debitCardProps: PropTypes.shape({ onAfterSuccess: PropTypes.func }),
}

LinkedAccountDetails.propTypes = {
  /**
   * Function to call after successful request
   */
  onAfterSuccess: PropTypes.func,
}

export default LinkedAccountDetails
