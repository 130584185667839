import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { gql, useQuery, useLazyQuery } from '@services/serviceUtils'
import Button from '@shared/components/button/Button'
import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'
import { ReactComponent as Chevron } from '@shared/images/icons/chevron.svg'

import { staticRoutes } from '@routing/routes'
import ProtectedRoute from '@routing/ProtectedRoute'
import PlaidLink from '@common/components/plaidLink/PlaidLink'
import MaxCardsModal from '@common/components/maxCardsModal/MaxCardsModal'
import LinkedAccountDetails from './LinkedAccountDetails'
import LinkedAccountStatus from './LinkedAccountStatus'

import { getActiveCardCount } from '@common/utils/cardUtils'
import { useDebitDepositFeesLimits } from '@common/utils'

import styling from './linkedAccounts.module.scss'

const plaidLinkDataQuery = gql`
  query PlaidAccounts {
    plaidAccounts {
      id
      linkingStatus
      linkingStatusDescriptor
      mask
      title
      ctas {
        caption
        ctaType
      }
    }
  }
`

const debitCardLinkDataQuery = gql`
  query LinkedCards {
    linkedCards {
      address {
        addr1
        addr2
        city
        state
        zip
      }
      expired
      expireDate
      id
      maskedNumber
      network
    }
  }
`

/**
 * Displays details for a Plaid linked account presented as a table row
 */
const LinkedAccountRow = ({ linkedAccount, onClick }) => {
  const isDebitCardAccount = !!linkedAccount?.address
  const dataCy = isDebitCardAccount ? 'linked-account-row-debit' : 'linked-account-row-plaid'

  return (
    <tr
      className='link-row'
      key={linkedAccount.id}
      onClick={() => onClick(linkedAccount)}
      data-cy={dataCy}
    >
      <th>
        <LinkedAccountStatus linkedAccount={linkedAccount} />
      </th>
      <td className='row-chevron'>
        <Chevron />
      </td>
    </tr>
  )
}

/**
 * Displays a list of PlaidLinkedAccountStatus
 */
const LinkedAccountsList = ({ accounts = [] }) => {
  const navigate = useNavigate()

  const handleLinkedAccountClick = linkedAccount => {
    navigate(staticRoutes.settingsLinkedAccountsLinkedAccountDetails.pathname, { state: { linkedAccount } })
  }

  return (
    <table className={classNames('simple-table', styling['linked-accounts-table'])}>
      <tbody>
        {accounts.map(linkedAccount => (
          <LinkedAccountRow
            key={linkedAccount.id}
            linkedAccount={linkedAccount}
            onClick={handleLinkedAccountClick}
          />
        ))}
      </tbody>
    </table>
  )
}

const LinkedAccountsView = ({
  error,
  loading,
  linkedAccountsData,
  linkedCardsError,
  linkedCardsLoading,
  linkedCardsData,
  plaidLinkProcessing,
  refetchPlaidLinks,
}) => {
  const navigate = useNavigate()
  const { webDebitCardTabapay } = useFlags()

  const [isMaxCardsModalOpen, setIsMaxCardsModalOpen] = useState(false)

  const activeCardCount = getActiveCardCount(linkedCardsData?.linkedCards)

  const { maxActiveCardLimit } = useDebitDepositFeesLimits({})

  const handleAddDebitCardClick = () => {
    if (activeCardCount >= maxActiveCardLimit) {
      // open modal displaying 'Number of debit cards limit reached. Please remove a card to add another.'
      setIsMaxCardsModalOpen(true)
    } else {
      navigate(staticRoutes.addDebitCard.pathname)
    }
  }

  return (
    <>
      <div className={classNames('settings-view-content', styling['linked-accounts-container'])}>
        <h2>Linked Accounts/Cards</h2>
        <p>
          Easily link your personal accounts and cards. Linking to business accounts not allowed.
        </p>

        <LoadingContainer
          error={error || linkedCardsError}
          errorMessage='Error retrieving Plaid Link data'
          loading={loading || plaidLinkProcessing || linkedCardsLoading}
        >
          <div className={styling['add-linked-items-container']}>
            <PlaidLink onAfterSuccess={refetchPlaidLinks} ghost />
            {webDebitCardTabapay && (
              <Button
                className={styling['debit-card-link-action-button']}
                onClick={handleAddDebitCardClick}
                isLoading={false}
                ghost
                data-cy='debit-card-link-action-button'
              >
                <div className={styling.plus}>&#43;</div>Add Debit Card
              </Button>
            )}
          </div>
          <LinkedAccountsList accounts={linkedAccountsData} />
        </LoadingContainer>
      </div>
      <MaxCardsModal
        isOpen={isMaxCardsModalOpen}
        toggleModal={() => setIsMaxCardsModalOpen(!isMaxCardsModalOpen)}
        closeModal={() => setIsMaxCardsModalOpen(false)}
      />
    </>
  )
}

const LinkedAccounts = () => {
  const [plaidLinkProcessing, setPlaidLinkProcessing] = useState(false)
  const [linkedAccountsData, setLinkedAccountsData] = useState([])

  const { webDebitCardTabapay } = useFlags()

  const [
    getPlaidLinks,
    { data: plaidLinkData, error, loading, refetch: refetchPlaidLinks },
  ] = useLazyQuery(plaidLinkDataQuery, {
    fetchPolicy: 'no-cache',
  })

  const {
    data: linkedCardsData,
    error: linkedCardsError,
    loading: linkedCardsLoading,
    refetch: refetchLinkedCards,
  } = useQuery(debitCardLinkDataQuery, { fetchPolicy: 'no-cache' })

  useEffect(() => {
    getPlaidLinks()
  }, [getPlaidLinks])

  useEffect(() => {
    const plaids = plaidLinkData?.plaidAccounts || []
    const cards = linkedCardsData?.linkedCards || []

    const accounts = [...plaids]

    if (webDebitCardTabapay) {
      accounts.push(...cards)
    }

    setLinkedAccountsData(accounts)
  }, [plaidLinkData, linkedCardsData, webDebitCardTabapay])

  /* Plaid Link's `onSuccess` is asynchronous, so these callbacks are set up to
   *   track when to show loaders and refetch the list data
   */

  const handlePlaidAfterSuccess = () => {
    setPlaidLinkProcessing(false)
    refetchPlaidLinks()
  }

  const handleDebitCardAfterSuccess = () => {
    refetchLinkedCards()
  }

  const handleAfterSuccess = isDebitCardAccount => {
    isDebitCardAccount ? handleDebitCardAfterSuccess() : handlePlaidAfterSuccess()
  }

  return (
    <Routes>
      <Route path=''>
      <Route
        index
        element={
          <ProtectedRoute
            component={LinkedAccountsView}
            componentProps={{
              error,
              linkedCardsError,
              loading,
              plaidLinkProcessing,
              linkedAccountsData,
              linkedCardsLoading,
              linkedCardsData,
              refetchPlaidLinks,
            }}
          />
        }
      />
      <Route
        path={staticRoutes.settingsLinkedAccountsLinkedAccountDetails.relativePathname}
        element={
          <ProtectedRoute
            component={LinkedAccountDetails}
            componentProps={{
              onAfterSuccess: handleAfterSuccess,
            }}
          />
        }
      />
      <Route
        path='*'
        element={<Navigate to={staticRoutes.settingsLinkedAccounts.pathname} />}
      />
      </Route>
    </Routes>
  )
}

export default LinkedAccounts
